import { toggleExpandable } from '../common/expandable';

(() => {
    const $siteFooterMenuDropdowns = document.querySelectorAll('.site-footer__menu-dropdown');

    if (!$siteFooterMenuDropdowns) return;

    const initSiteFooterMenuDropdowns = () => {
        $siteFooterMenuDropdowns.forEach($siteFooterMenuDropdown => {
            const $siteFooterMenuDropdownHeading = $siteFooterMenuDropdown.querySelector('.site-footer__menu-dropdown-heading');
            const $siteFooterMenuDropdownContent = $siteFooterMenuDropdown.querySelector('.site-footer__menu-dropdown-content');

            if (!$siteFooterMenuDropdownHeading || !$siteFooterMenuDropdownContent) return;

            $siteFooterMenuDropdownHeading.addEventListener('click', () => {
                toggleExpandable($siteFooterMenuDropdownContent);
            });

            $siteFooterMenuDropdownContent.addEventListener('expandableopen', () => {
                $siteFooterMenuDropdownHeading.setAttribute('aria-expanded', 'true');
                $siteFooterMenuDropdownHeading.classList.add('site-footer__menu-dropdown-heading--active');
            });

            $siteFooterMenuDropdownContent.addEventListener('expandableclose', () => {
                $siteFooterMenuDropdownHeading.setAttribute('aria-expanded', 'false');
                $siteFooterMenuDropdownHeading.classList.remove('site-footer__menu-dropdown-heading--active');
            });
        });
    };

    initSiteFooterMenuDropdowns();
})();
