(() => {
    let winWidth = null;

    const setScreenHeights = () => {
        const $fullHeightEls = document.querySelectorAll('.full-height');
        const $minFullHeightEls = document.querySelectorAll('.min-full-height');
        const $wpadminbar = document.querySelector('#wpadminbar');

        if ($fullHeightEls) {
            if (winWidth !== window.innerWidth) {
                $fullHeightEls.forEach($el => {
                    $el.style.height = `${window.innerHeight - ($wpadminbar?.offsetHeight && window.innerHeight ? $wpadminbar?.offsetHeight : 0)}px`;
                });
            }
        }

        if ($minFullHeightEls) {
            if (winWidth !== window.innerWidth) {
                $minFullHeightEls.forEach($el => {
                    $el.style.minHeight = `${window.innerHeight - ($wpadminbar?.offsetHeight && window.innerHeight ? $wpadminbar?.offsetHeight : 0)}px`;
                });
            }
        }
    }

    window.addEventListener('resize', () => {
        setScreenHeights();

        winWidth = window.innerWidth;
    });

    window.addEventListener('load', () => {
        setScreenHeights();
    });

    setScreenHeights();
})();
