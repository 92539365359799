const expandableOpenEvent = new CustomEvent('expandableopen', {
    detail: {},
    bubbles: true,
    cancelable: true,
    composed: false
});

const expandableCloseEvent = new CustomEvent('expandableclose', {
    detail: {},
    bubbles: true,
    cancelable: true,
    composed: false
});

const expandableAfterOpenEvent = new CustomEvent('expandableafteropen', {
    detail: {},
    bubbles: true,
    cancelable: true,
    composed: false
});

const expandableAfterCloseEvent = new CustomEvent('expandableafterclose', {
    detail: {},
    bubbles: true,
    cancelable: true,
    composed: false
});

export function showExpandable($el, delay = 500) {
    if (!$el || !$el.classList.contains('citrus-expandable') || !$el.classList.contains('citrus-expandable--hide')) return;

    $el.dispatchEvent(expandableOpenEvent);

    $el.style.height = '0px';
                        
    window.requestAnimationFrame(() => {
        $el.classList.remove('citrus-expandable--hide');
        $el.style.height = `${$el.scrollHeight}px`;

        setTimeout(() => {
            $el.style.height = '';

            $el.dispatchEvent(expandableAfterOpenEvent);
        }, delay);
    });
}

export function hideExpandable($el, delay = 1000) {
    if (!$el || !$el.classList.contains('citrus-expandable') || $el.classList.contains('citrus-expandable--hide')) return;

    $el.dispatchEvent(expandableCloseEvent);

    $el.style.height = `${$el.scrollHeight}px`;

    window.requestAnimationFrame(() => {
        $el.classList.add('citrus-expandable--hide');
        $el.style.height = '0px';

        setTimeout(() => {
            if (!$el.classList.contains('citrus-expandable--hide')) return;

            $el.dispatchEvent(expandableAfterCloseEvent);
        }, delay);
    });
}

export function toggleExpandable($el) {
    if (!$el || !$el.classList.contains('citrus-expandable')) return;

    if ($el.classList.contains('citrus-expandable--hide')) {
        showExpandable($el);
    } else {
        hideExpandable($el);
    }
}
