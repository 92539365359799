(() => {
    const $siteHeaderInner = document.querySelector('.site-header__inner');

    let $anchorLinks = document.querySelectorAll('a');

    $anchorLinks = $anchorLinks ? Array.from($anchorLinks).filter($anchorLink => {
        const url = new URL($anchorLink.href);

        return url?.hash && document.querySelector(url?.hash);
    }) : $anchorLinks;

    if (!$anchorLinks) return;

    $anchorLinks.forEach($anchorLink => {
        $anchorLink.addEventListener('click', e => {
            const url = new URL($anchorLink.href);

            const $targetEl = url ? document.querySelector(url?.hash) : null;

            if (!$targetEl) return;

            e.preventDefault();

            window.scrollTo({
                top: $targetEl?.getBoundingClientRect()?.y + window.scrollY - ($siteHeaderInner ? $siteHeaderInner.offsetHeight : 0),
                behavior: 'smooth',
            });
        });
    });
})();
